@import 'FA_STORYBOOK_STYLES/colours.sass';

.fa-import-runner {
    header {
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        .fa-import-runner--status {
            display: flex;
            align-items: center;

            .ant-tag {
                margin-left: 4px; } }

        .fa-import-runner--controls {
            margin-left: auto;
            display: flex;
            flex-direction: row-reverse; } }

    .ant-progress {
        margin-bottom: 2em; }

    .fa-import-report--record-panel {
        .fa-data-table {
            tbody {
                tr:not(.ant-table-expanded-row) {
                    cursor: pointer;

                    &:after {
                        content: '+';
                        font-size: 28px;
                        position: absolute;
                        right: 14px;
                        opacity: 0.2;
                        color: $primary; }

                    &.is-rebrand {
                        &:after {
                            color: $rebrand; } }

                    &:hover {
                        opacity: 1;

                        &:after {
                            opacity: 1; }

                        .fa-import-runner--responseIndex {
                            color: $text-body; } } } }

            td.fa-import-runner--responseIndex {
                width: 70px;
                color: $text-disabled; }

            .fa-import-runner--status {
                width: 70px; }

            .fa-import-runner--id {
                .ant-table-header-column {
                    small {
                        margin-left: 0.5em;
                        color: $text-body;
                        font-weight: normal; } } }

            .fa-import-runner--error-message {
                display: block;
                max-width: 600px;
                overflow: hidden;
                text-overflow: ellipsis; }

            .fa-import-runner--error-detail {
                > p {
                    white-space: break-spaces; } } } }

    .fa-import-runner--buttons {
        display: flex;
        margin-top: 1em;
        white-space: break-spaces; } }
