@import 'FA_STORYBOOK_STYLES/colours.sass';

.Authentication {
    width: 420px;
    padding: 2em;
    background: $white;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

    .fa-page-title {
        color: $primary !important; }

    .fa-page-title--rule {
        border-color: $primary; }

    .ant-form-explain {
        text-align: right; } }
