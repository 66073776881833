@import 'FA_STORYBOOK_STYLES/colours.sass';

.fa-nav-bar {
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 1;
    flex-grow: 1;
    width: 100vw;
    background: $white;

    .fa-nav-bar--logo {
        padding: 0.5em;
        display: flex;
        flex-grow: 1;
        align-items: center;
        border-bottom: 1px solid $grey-e;
        width: 25%;

        > * {
            cursor: pointer; }

        > img {
            margin-right: 0.5em;
            border-radius: 6px; }

        .fa-overline-label {
            font-size: 11px; } }

    .fa-nav-bar--navigation {
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: 50%;
        min-width: 800px;
        margin: auto;

        .fa-nav-bar--new-session {
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: flex-end;
            height: 48px;
            border-bottom: 1px solid $grey-e; } }

    .fa-nav-bar--popover-trigger-icon {
        margin-left: 0.5em;
        font-size: 0.8em;

        .fa-ant-icon {
            &.anticon-down {
                color: $text-disabled; } } }

    .fa-nav-bar--user {
        display: flex;
        padding: 0 0.5em;
        width: 25%;
        cursor: pointer;
        border-bottom: 1px solid $grey-e;

        .fa-nav-bar--user-details {
            height: 48px;
            margin-left: auto;
            color: $text-body;
            display: flex;
            align-items: center;

            .fa-nav-bar--user-icon {
                    font-size: 20px;
                    margin-right: 0.5em; }

            &.ant-popover-open {
                color: $primary;

                .anticon-down {
                    color: $primary; } }

            &.is-rebrand {
                &.ant-popover-open {
                    color: $rebrand;

                    .anticon-down {
                        color: $rebrand; } } } } } }

.fa-nav-bar--user-popover {
    > pre {
        white-space: pre-wrap; } }


@media (max-width: 1300px) {
    .fa-nav-bar {
        .fa-nav-bar--logo {
            .fa-nav-bar--back {
                display: inline-block; }

            > *:not(img) {
                display: none; } }

        .fa-nav-bar--user {
            .fa-nav-bar--user-details {
                .fa-nav-bar--user-name {
                    display: none; }

                .fa-nav-bar--user-icon {
                    margin-right: 0; } } } } }
