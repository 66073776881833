@import 'FA_STORYBOOK_STYLES/colours.sass';

.fa-import-history {
    > header {
        margin-bottom: 0.5em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .fa-section-title {
            display: inline; } }

    .ant-list-item {
        padding: 0 1em 1em 1em;
        border: 1px solid $grey-e;
        border-radius: 10px;
        margin-bottom: 1em; } }
