@import 'FA_STORYBOOK_STYLES/colours.sass';

.fa-import-report {
    margin-bottom: 0.5em;

    .fa-import-report--record-panel {
        .fa-hoc-wrapper {
            display: inline !important; } }

    > * {
        display: block;
        margin-bottom: 0.5em !important; }

    .fa-data-table {
        .is-success-row {
            color: $emphasis-green;
            background-color: rgba($emphasis-green, 0.1); }

        .is-warning-row {
            color: $emphasis-yellow;
            background-color: rgba($emphasis-yellow, 0.1); }

        .is-error-row {
            color: $emphasis-red;
            background-color: rgba($emphasis-red, 0.1); } } }
