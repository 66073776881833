@import 'FA_STORYBOOK_STYLES/colours.sass';

.fa-csv-file-preview {
    > header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1em;
        margin-bottom: 1em;
        border-bottom: 1px solid $primary-text-highlight;

        &.is-rebrand {
            border-bottom: 1px solid $rebrand-text-highlight; } }

    .fa-csv-file-preview--table-container {
        height: calc(100vh - 300px);

        &.is-fixed-width {
            // allow horizontal scroll for columns rendered out of window
            overflow-x: auto; }

        .ReactVirtualized__Table__headerRow {
            text-transform: none;
            background: $grey-f;

            .ReactVirtualized__Table__headerColumn {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden; } } } }
