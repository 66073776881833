@import 'FA_STORYBOOK_STYLES/colours.sass';
@import 'FA_STORYBOOK_STYLES/mixins/fa-button.mixin.sass';

* {
    box-sizing: border-box; }

body {
    color: $text-body;

    a {
        text-decoration: none; }

    p {
        margin-top: 0; }

    pre {
        margin: 0 0 0.5em 0; }

    &.is-rebrand {
        .ant-modal-confirm-body-wrapper {
            .fa-ant-button {
                @include fa-button-rebrand-styles; } } } }

.fa-import-tool {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;

    > *:not(.Authentication) {
        width: 50%;
        min-width: 800px; }

    .fa-hoc-wrapper {
        display: flex; } }
