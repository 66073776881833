@import 'FA_STORYBOOK_STYLES/colours.sass';

.Progress {
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid $grey-e;

    .step-description {
        &.is-error {
            color: $danger; }

        &.is-warning {
            color: $emphasis-yellow; }

        &.is-success {
            color: $emphasis-green; } } }

