.fa-export-runner {
    width: 50%;

    .ant-progress {
        margin-bottom: 1em; }

    .fa-ant-button {
        margin-bottom: 0.5em; }

    .fa-export-runner--history {
        header {
            display: flex;
            justify-content: space-between;
            align-items: center; } }

    .fa-export-runner--with-archived {
        display: flex;
        width: 100%;

        .button-width {
            width: 52%; }

        .checkbox-area {
            display: flex;
            padding-left: 15px;
            align-items: center; } }

    .fa-export-runner--without-archived {
        display: inline-block;
        width: 52%; }

    .fa-export-runner--item-archival-false {
        display: flex;
        width: 100%; } }
