.fa-csv-file-summary {
    margin-bottom: 0.5em;

    > main {
        display: flex;
        margin-top: 1em;

        .ant-alert {
            flex-grow: 1;
            padding: 12px 12px 0 52px;

            .ant-alert-icon {
                left: 18px; }

            &:not(:last-child) {
                margin-right: 1em; } } } }
