@import 'FA_STORYBOOK_STYLES/colours.sass';

.annotated-json {
    padding: 1em;
    background: $grey-f;
    border: 1px solid $grey-e;
    border-radius: 5px;

    .json-line {
        margin-left: 1em;

        &:not(:last-child) {
            margin-bottom: 2px; }

        .json-value {
            .ant-tag {
                margin-right: 0; } }

        .json-list {
            margin-left: 2em; } } }

