@import 'FA_STORYBOOK_STYLES/colours.sass';

.Help {
    code {
        color: $primary-dark;
        background-color: $primary-opaque-light-1;
        padding: 2px;
        border-radius: 4px; }

    .fa-hoc-wrapper {
        display: inline-flex !important; }

    &.is-rebrand {
        code {
            color: $rebrand-dark;
            background-color: $rebrand-opaque-light-1; } } }
