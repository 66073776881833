.fa-configuration {
    .fa-configuration--controls {
        display: flex;
        justify-content: space-between;
        padding: 1em 0; }

    .fa-data-table {
        margin-bottom: 3em;

        .fa-configuration--mapping-cell {
            display: flex;
            align-items: center; }

        .fa-configuration--mapping-status {
            display: flex;
            align-items: center;

            .fa-svg-icon-rounded-warning {
                position: relative;
                top: 2px; } } } }
