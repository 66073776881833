.fa-csv-validation-preview {
    position: relative;

    > header {
        position: absolute;
        top: 0.5em; }

    .fa-data-table {
        .ant-spin-container {
            .ant-table-content {
                overflow-x: scroll;
                max-height: calc(100vh - 400px); } }

        .ant-table-header-column {
            > div {
                text-align: center; } }

        .ant-tag {
            max-width: 400px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: pre; }

        td {
            white-space: pre; } } }

.ant-tooltip-inner {
    white-space: pre-wrap; }
