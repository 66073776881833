@import 'FA_STORYBOOK_STYLES/colours.sass';

.Obfuscate {
    cursor: pointer;
    color: $grey-3;
    background-color: $grey-3;
    border-radius: 2px;
    padding: 0 2px;

    &:hover {
        color: $grey-f; } }
